
import React, {Suspense, lazy} from 'react';
import { ActivityIndicator, SafeAreaView } from 'react-native';
import { initializeCacheValue } from './source/CachedValue';
import CacheValueStorage_LocalStorage from './source/CacheValueStorage_ LocalStorage';

initializeCacheValue(new CacheValueStorage_LocalStorage());
// initializeService();

const MainApp = lazy(()=> import('./MainApp'));

const LoadingPage = () => {
  return (
    <SafeAreaView style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
      <ActivityIndicator />
    </SafeAreaView>
  );
};

export default function App() {
  return <Suspense fallback={<LoadingPage />}>
    <MainApp />
  </Suspense>
};

