function getStoreKey(key, byUser, forDate) {
  if (forDate) {
    return `cached_date_${key}`;
  } else {
    return `cached_val_${key}`;
  }
}

export default class CacheValueStorage_LocalStorage {
  write(key, byUser, value) {
    let d = `${new Date().valueOf()}`;
    let dKey = getStoreKey(key, byUser, true);

    let v = JSON.stringify(value);
    let vKey = getStoreKey(key, byUser, false);

    localStorage.setItem(dKey, d);
    localStorage.setItem(vKey, v);
  }

  /**
   *
   * @param {String} key
   * @param {Boolean} byUser
   * @param {(json:{value:Object, date:Date})=>{} } callback returns a object {value:Object, date}
   */
  async read(key, byUser) {
    let dKey = getStoreKey(key, byUser, true);
    let vKey = getStoreKey(key, byUser, false);
    try {
      let date = new Date(Number(await localStorage.getItem(dKey)));
      let value = JSON.parse(await localStorage.getItem(vKey));
      return {value, date};
    } catch (err) {
      return null;
    }
  }
}
