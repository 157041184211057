import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import {skip, takeUtil} from 'rxjs/operators';

export function initializeCacheValue(storage) {
  global.cacheValueStorage = storage;
}

function defaultStorage() {
  return global.cacheValueStorage;
}

export default class CachedValue<T> {
  
  _subject: BehaviorSubject<T>;
  interval: number;
  byUser: boolean;
  date: Date;
  overrideStorage:any;
  key: string;

  //** cached value will delayed a bit before it can get its value from asyncstorage this delay can cause issue if u expect it to assume disk value right away. observe this initializedFromStorage subject, when it is true, the value had been loaded. will also turn true even if none disk content exists yet */
  public readonly initializedFromStorage: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /**
   *
   * @param {String} key - use null to just cache in memory (i.e. vanila behavior subject, but with a isStale functionality)
   * @param {T} defaultValue - must be simple object that can be stringify
   * @param {Number} interval - the time interval in milliseconds till it is consider stale
   * @param {Boolean} byUser - indicate if cache is by user (need to have user id store)
   */
  constructor(key:string, defaultValue:T, interval:number, byUser:boolean=false,  onDeserialize:(T) => T = null, overrideStorage:any = null) {
    this._subject = new BehaviorSubject(defaultValue);
    this.interval = interval;
    this.byUser = byUser;
    this.date = new Date(0); //minimal date
    this.overrideStorage = overrideStorage;

    if (key) {
      this.key = key;

      this._subject.pipe(skip(2)).subscribe(v => {
        this.storage.write(key, byUser, v);
      });

      this.storage
        .read(key, byUser)
        .then(ret => {
          if (ret == null || ret.value == null || ret.date == null) {
            this._subject.next(this._subject.value);
          } else {
            this.date = ret.date;
            if(onDeserialize) {
              this._subject.next(onDeserialize(ret.value));
            }
            else {
              this._subject.next(ret.value);
            }
          }
          this.initializedFromStorage.next(true);
        })
        .catch(() => {
          //when load fail, forced the first update to the same initial value, so that the skip(1) will pass and will start save cache
          this._subject.next(this._subject.value);

          this.initializedFromStorage.next(true);
        });
    }
  }

  /**
   * @returns {Observable}
   */
  asObservable() {
    return this._subject.asObservable();
  }

  get storage() {
    return this.overrideStorage ? this.overrideStorage : defaultStorage();
  }

  get value() {
    return this._subject.value;
  }

  set value(val) {
    this._subject.next(val);
  }

  next(val) : void {
    this._subject.next(val);
  }

  /**
   * 
   * @param  {...any} params
   * @returns {Observable}
   */
  pipe<A>(...params) : Observable<A> {
    return this._subject.pipe(...params);
  }

  /**
   * 
   * @param  {...any} params 
   * @returns {Subscription}
   */
  subscribe(...params) : Subscription {
    return this._subject.subscribe(...params);
  }

  dispose() {
    this._subject.complete();
  }
}
